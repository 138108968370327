import React, { useState, useEffect } from 'react'
import { Chessboard } from 'react-chessboard'
import { Chess } from 'chess.js'

const ChessDisplay = ({ fen, onPieceMove, onReset }) => {
  const [game, setGame] = useState(new Chess())
  const [originalFen, setOriginalFen] = useState(fen)

  // Update game state when FEN changes
  useEffect(() => {
    const newGame = new Chess(fen)
    setGame(newGame)
    setOriginalFen(fen) // Store original FEN for reset
  }, [fen])

  // Called when a piece is dropped
  const onDrop = (sourceSquare, targetSquare) => {
    try {
      // Create move in UCI format (e.g., 'e2e4')
      const move = sourceSquare + targetSquare

      // Create a temp game for move validation
      const tempGame = new Chess(game.fen())

      // Try to make the move
      const legalMoves = tempGame.moves({ verbose: true })
      const isLegal = legalMoves.some(m => m.from === sourceSquare && m.to === targetSquare)

      if (isLegal) {
        // If move is legal, make it on our game instance
        tempGame.move({ from: sourceSquare, to: targetSquare })

        // Update game state
        setGame(tempGame)

        // Call the parent's onPieceMove handler with the UCI move
        onPieceMove(move)
        return true
      }

      return false
    } catch (error) {
      console.error('Error making move:', error)
      return false
    }
  }

  const handleReset = () => {
    const resetGame = new Chess(originalFen)
    setGame(resetGame)
    onReset && onReset()
  }

  return (
    <div className="chess-display">
      <Chessboard
        position={game.fen()}
        boardWidth={400}
        onPieceDrop={onDrop}
        boardOrientation={fen.includes(' w ') ? 'white' : 'black'}
        customDarkSquareStyle={{ backgroundColor: '#769656' }}
        customLightSquareStyle={{ backgroundColor: '#eeeed2' }}
      />
      <button onClick={handleReset} className="reset-button">
        Reset Board
      </button>
    </div>
  )
}

export default ChessDisplay
